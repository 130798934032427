<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body>
          <div class="p-1">
            <h3 class="m-0">{{ $t("Eventos") }}</h3>
          </div>
          <b-table hover responsive :items="items" :fields="columns">
            <template #cell(actions)="data">
              <span>
                <b-link
                  :to="{ name: 'editEvent', params: { id: data.item.id } }"
                  class="mr-1"
                >
                  <feather-icon icon="Edit2Icon" size="16" />
                </b-link>
                <span
                  class="text-danger cursor-pointer"
                  @click="deleteEvent(data.item.id, data.item.name)"
                >
                  <feather-icon icon="TrashIcon" />
                </span>
              </span>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { BLink, BTable, BCard, BButton, BRow, BCol } from "bootstrap-vue";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import store from "@/store";

export default {
  components: {
    BCard,
    BLink,
    BButton,
    BTable,
    BRow,
    BCol,
  },
  data() {
    return {
      log: [],
      dir: false,
      pages: ["10", "15", "25"],
      columns: [
        {
          label: "Nombre",
          key: "name",
        },
        {
          label: "Alias",
          key: "alias",
        },
        {
          label: "Acciones",
          key: "actions",
          class: "text-right",
        },
      ],
      searchTerm: "",
    };
  },
  computed: {
    ...mapGetters({
      items: "events/getItems",
      totalItems: "events/getTotalItems",
    }),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.chargeData();
  },
  methods: {
    ...mapActions({
      list: "events/getList",
      delete: "events/delete",
    }),
    chargeData() {
      this.list();
    },
    deleteEvent(id, name) {
      this.$bvModal
        .msgBoxConfirm(this.$t("EliminarConfirm", { nombre: name }), {
          bodyClass: "bodyDelete",
          footerClass: "footerDelete",
          okVariant: "danger",
          okTitle: this.$t("Eliminar"),
          cancelTitle: this.$t("Cancelar"),
          cancelVariant: "outline-danger",
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.delete(id);
          }
        });
    },
  },
};
</script>
